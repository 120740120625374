.result-grid {
  clear: both;

  .table-grid {
    .table;
  }

  .table-grid .label {
    font-weight: normal;
    text-transform: uppercase;
    font-size: 0.5em;
    margin-left: 10px;
  }

  .table-grid thead th {
    font-size: 12px;
    text-transform: uppercase;
  }

  td small {
    font-size: 0.8em;
  }

  .table-options {
    position: relative;
    margin-bottom: 20px;
  }

  .filter-options,
  .sort-options {
    display: inline-block;
    margin-right: 5px;
  }

  .sorted-by,
  .filter-options {
    margin-left: 5px;
    padding: 0 10px;
    background: #f9f9f9;
    font-weight: 600;
    color: inherit;
    display: inline-block;

    &:hover {
      color: inherit;
    }
  }

  .result-grid-search {
    .pull-right;
    .form-inline;

    .input-search {
      padding: 3px 8px 3px;
      font-size: 14px;
      vertical-align: middle;
    }

    .btn,
    .btn-primary,
    .btn-default {
      border: 0;
      margin-left: -10px;
      position: relative;
    }
    .btn-sm {
      padding: 5px 10px;
    }
  }
}
