.icon-arrow-right,
.icon-arrow-left {
  font-size: 16px !important;
  position: relative;
  top: 2px;
}

.page-header {
  padding-bottom: ((@line-height-computed / 2) - 1);
  margin: 0 0 20px;
  border-bottom: 1px solid @trim;
}

.truncate {
  max-width: 100%;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

// Alignment
.text-left {
  text-align: left;
}
.text-right {
  text-align: right;
}
.text-center {
  text-align: center;
}
.text-justify {
  text-align: justify;
}
.text-nowrap {
  white-space: nowrap;
}

.align-left {
  text-align: left;
  justify-content: flex-start;
}

.align-center {
  text-align: center;
  justify-content: center;
}

.align-right {
  text-align: right;
  justify-content: flex-end;
}

@spacer-x: 20px;
@spacer-y: 20px;

// Margin

.m-b-0 {
  margin-bottom: 0 !important;
}
.m-t-1 {
  margin-top: @spacer-y !important;
}
.m-b-1 {
  margin-bottom: @spacer-y !important;
}
.m-b-2 {
  margin-bottom: (@spacer-y * 1.5) !important;
}
.m-l-1 {
  margin-left: @spacer-x !important;
}

// Padding

.p-a-0 {
  padding: 0 0 !important;
}
.p-t-0 {
  padding-top: 0 !important;
}
.p-a-1 {
  padding: @spacer-y @spacer-x !important;
}
.p-t-1 {
  padding-top: @spacer-y !important;
}
.p-b-1 {
  padding-bottom: @spacer-y !important;
}
.p-t-2 {
  padding-top: (@spacer-y * 1.5) !important;
}
.p-y-2 {
  padding-top: (@spacer-y * 1.5) !important;
  padding-bottom: (@spacer-y * 1.5) !important;
}

// Clear

.c-b {
  clear: both;
}
.c-l {
  clear: left;
}
.c-r {
  clear: right;
}
