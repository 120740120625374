pre.highlight {
  background: #f5fafe;
  box-shadow: 0 2px 0 rgba(37, 11, 54, 0.04);
  tab-size: 2em;

  .c {
    color: #a8a1bd;
  }
  .err {
    background: #e03e2f;
    color: #ffffff;
  }
  .k {
    color: #6c5fc7;
  }
  .l {
    color: #f36e4f;
  }
  .n {
    color: #423b87;
  }
  .o {
    color: #564f64;
  }
  .p {
    color: #a8a1bd;
  }
  .cm {
    color: #a8a1bd;
  }
  .cp {
    color: #a8a1bd;
  }
  .c1 {
    color: #a8a1bd;
  }
  .cs {
    color: #a8a1bd;
  }
  .gd {
    color: #e03e2f;
  }
  .ge {
    font-style: italic;
  }
  .gh {
    color: #564f64;
    font-weight: bold;
  }
  .gi {
    color: #57be8c;
  }
  .gp {
    color: #57be8c;
    font-weight: bold;
  }
  .gs {
    font-weight: bold;
  }
  .gu {
    color: #2e7d6f;
    font-weight: bold;
  }
  .kc {
    color: #6c5fc7;
  }
  .kd {
    color: #6c5fc7;
  }
  .kn {
    color: #2e7d6f;
  }
  .kp {
    color: #5d91c9;
  }
  .kr {
    color: #6c5fc7;
  }
  .kt {
    color: #f9a66d;
  }
  .ld {
    color: #57be8c;
  }
  .m {
    color: #f36e4f;
  }
  .s {
    color: #57be8c;
  }
  .na {
    color: #5d91c9;
  }
  .nb {
    color: #5d91c9;
  }
  .nc {
    color: #f9a66d;
  }
  .no {
    color: #f9a66d;
  }
  .nd {
    color: #2e7d6f;
  }
  .ni {
    color: #564f64;
  }
  .ne {
    color: #e03e2f;
  }
  .nf {
    color: #5d91c9;
  }
  .nl {
    color: #564f64;
  }
  .nn {
    color: #f9a66d;
  }
  .nx {
    color: #5d91c9;
  }
  .py {
    color: #564f64;
  }
  .nt {
    color: #2e7d6f;
  }
  .nv {
    color: #e03e2f;
  }
  .ow {
    color: #2e7d6f;
  }
  .w {
    color: #564f64;
  }
  .mf {
    color: #f36e4f;
  }
  .mh {
    color: #f36e4f;
  }
  .mi {
    color: #f36e4f;
  }
  .mo {
    color: #f36e4f;
  }
  .sb {
    color: #57be8c;
  }
  .sc {
    color: #564f64;
  }
  .sd {
    color: #a8a1bd;
  }
  .s2 {
    color: #57be8c;
  }
  .se {
    color: #f36e4f;
  }
  .sh {
    color: #57be8c;
  }
  .si {
    color: #f36e4f;
  }
  .sx {
    color: #57be8c;
  }
  .sr {
    color: #57be8c;
  }
  .s1 {
    color: #57be8c;
  }
  .ss {
    color: #57be8c;
  }
  .bp {
    color: #564f64;
  }
  .vc {
    color: #e03e2f;
  }
  .vg {
    color: #e03e2f;
  }
  .vi {
    color: #e03e2f;
  }
  .il {
    color: #f36e4f;
  }
}
